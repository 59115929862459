.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two items per row */
  gap: 20px; /* Space between items */
  padding: 20px;
}

.grid-item {
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #f4f4f4;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-image {
  max-width: 100px;
  max-height: 100px;
  margin-bottom: 10px;
}
.imagePicked {
  max-width: 150px;
  max-height: 250px;
  margin-bottom: 10px;
}
